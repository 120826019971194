import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header"]
  static classes = ["headerVisibility"]

  open() {
    this.headerTarget.classList.remove(this.headerVisibilityClass)
    document.body.style.overflow = "hidden"
  }

  close() {
    this.headerTarget.classList.add(this.headerVisibilityClass)
    document.body.style.overflow = null
  }
}
