import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    zoom: {
      type: Boolean,
      default: false,
    },
  }

  zoom() {
    this.zoomValue = !this.zoomValue
  }

  zoomIn() {
    this.zoomValue = true
  }

  zoomOut() {
    this.zoomValue = false
  }

  zoomValueChanged() {
    this.element.dataset.zoom = this.zoomValue

    if (this.zoomValue) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = null
    }
  }
}
