export default `
#define S(a,b,t) smoothstep(a,b,t)

// Rotation Function
mat2 Rot(float a) {
  float s = sin(a);
  float c = cos(a);
  return mat2(c, -s, s, c);
}

// Create Noise
// Created by inigo quilez - iq/2014
// License Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
vec2 hash( vec2 p )
{
    p = vec2( dot(p,vec2(2127.1,81.17)), dot(p,vec2(1269.5,283.37)) );
  return fract(sin(p)*43758.5453);
}

float noise( in vec2 p )
{
    vec2 i = floor( p );
    vec2 f = fract( p );

  vec2 u = f*f*(3.0-2.0*f);

    float n = mix( mix( dot( -1.0+2.0*hash( i + vec2(0.0,0.0) ), f - vec2(0.0,0.0) ), 
                        dot( -1.0+2.0*hash( i + vec2(1.0,0.0) ), f - vec2(1.0,0.0) ), u.x),
                   mix( dot( -1.0+2.0*hash( i + vec2(0.0,1.0) ), f - vec2(0.0,1.0) ), 
                        dot( -1.0+2.0*hash( i + vec2(1.0,1.0) ), f - vec2(1.0,1.0) ), u.x), u.y);
  return 0.5 + 0.5*n;
}

// Vars
uniform vec3 color1;
uniform vec3 color2;
uniform vec3 color3;
uniform vec3 color4;
uniform vec3 iResolution;
uniform float iTime;

varying vec2 vUv;

void main() {
  
  float ratio = iResolution.x / iResolution.y;
  vec2 tUv = vUv;
  tUv -= 0.25;
  
  // Rotation with Noise
  float degree = noise(vec2(iTime * 0.1, tUv.x * tUv.y));
  tUv.y *= 1.0 / ratio;
  tUv *= Rot( radians( (degree - 0.5) * 720.0 + 180.0 ));
  tUv.y *= ratio;
  
  // Wave warp with sin
  float frequency = 2.0;
  float amplitude = 20.0;
  float speed = iTime * 1.25;
  
  tUv.x += sin( tUv.y * frequency + speed) / amplitude;
  tUv.y += sin( tUv.x * frequency * 1.5 + speed ) / ( amplitude * 0.5);
  
  // Draw the Image
  vec3 layer1 = mix(color1, color2, S( -.3, .3, ( tUv * Rot( radians( -6.0))).x));
  vec3 layer2 = mix(color3, color4, S( -.3, .3, ( tUv * Rot( radians( -6.0))).x));

  vec3 finalComp = mix(layer1, layer2, S( 0.5, -.3, tUv.y ));
  
  vec3 col = finalComp;
  
  gl_FragColor = vec4(col, 1.0);
}
`