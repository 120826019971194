export default `%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMKdc:;ckNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMWXOxdddddxOKWMMMMWXOxdxOXWMMMMNOxdxOXWMWKxdx0WMNOxdkXWMWKkddx0WMMNOxdkXNl     .kMW0xdkKWWKxdx0WMMMWKkxddx0NWMMMNOxdkXMW0xdxKWMMWX0kdddk0NMMMMNOxdddddx0WMM
MMMMMWO,         .lXMMKc.     .dNMWk,     'ONx.   .xKl.   ,0Xl.    .lXKc    :0d.     dXo.   'kx.   .xWNx;.      'oXMK:    ;Od.   .kWKl'      .,dNWO,        .kMM
MMMMMX:            oW0,        .kMO'       cO,     ld.    .xl       .xd.    '0Wkl;..;Ok.    .c,     oKc           lNd.    'l'    .dk'           oK:         'OMM
MMMMMO.     ,.     cKc    .    .kWo        co.    .dc     ,x,       .d:     :KKxolcoKWo     ':.    .dl     ',     ,O:     ;c.    .d;     ,,     ;d.     '::o0WMM
MMMMWd     .c.    .dk.   .'.   .OX;        lc     ,x,     ld.       'o'     ox.     o0;     ;:     ,d'     c;     :x'     c;     ;o.    .l,     ll.    .OWMMMMMM
MMMMX:     ;c.    .kl    .,    ,KO.    .'..l,     cd.    .dc     .' ;c.    .d;      cx.    .:,     co.    .c'     lo.    .c'     ll     .cl. ..lO:     :XMMMMMMM
MMMM0'     c;     ;x'    ,'    ;Kd     ., 'c.    .dl     ,x,     ,. :;     ;d.     .oo     .'.    .oc     ,c.    .dc     ,c.    .kd      .;ok0XW0'     'odONMMMM
MMMWd.    .l'     cc    .:.    cK:     ,, ,;     ,x,     cd.    .;..:'     ll      'x:            ,d,     c:     ;x,     c;     ;KK;       .:0WWd.        .xMMMM
MMMNc     ,:.    .l'    ,:.    ok.     :' ;'     cd.    .dl     ':..;.    .d:      :x.            co.    .c'     ld.    .c'     lWMKl.       .OXc         .kMMMM
MMM0'           .lc     :;    .dd.    .c..,.    .ol     'x;     ;: ',     ;d'      oo.    .,.    .oc     ,c.    .dc     ,c.    .kWX0Od:.      cO'     .'':kWMMMM
MMMx.          'xk'     .     .d:     ;c..,     'x;     cd.    .c, '.     lo.     .x:     ;:     'd,     ::     ,x,     ::     ,Oo.  .:o.     co.    .xNNWMMMMMM
MMNc     .;:cokXNo            'o'     cc ..     :d.    .ol     'o' ..    .o:      ;x'     :,     :d.    .c,     cd.    .c,     co.    'l.    .lc     ,KMMMMMMMMM
MMK,     lNMMMMM0,     ..     ,c.    .o;       .oo     'x;     :o.       ,d'      lo.    .c.    .oo     'c.    .xl     ,c.    .o:     ;c.    'd,     ,xOKWMMMMMM
MMk.    .xMMMMMWo     'x:     ,,     ;x'       'x:     :x.    .oo        co.     .dc     ,:     .Od.    ..     lKl     ..     :0c     ..    .od.        .kMMMMMM
MWd.    ;KMMMMMNc     cO;    .c;    .dK:      .d0,    .xx.    ,0k.      ,Oo      :O:    .lc     cNXc         .oXMO'         .cKW0,         .oXd         .xMMMMMM
MMXo;',l0WMMMMMW0c,';oXWOc,';dXOc,';xNMKo;,';cOWWk:,':xNXd;',c0WNkc,',:dKWKl,',okKWOc,';xXKl,';oXMMNkl;,'';cdKWMMWKo;.....,cONMMMXxc;''';cdKWMKl,'''''':xNMMMMMM
MMMMWNWMMMMMMMMMMWWNWMMMMWWNWMMMWWWWMMMMMWNNWMMMMMWWNWMMMMWNWWMMMMMWNWWMMMMMWNWMMMMMWWNWMMMMWNWMMMMMMMWWNNWMMMMMMMMMWXKKKXWMMMMMMMMMWWNNWMMMMMMMWNNNNNNWMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM`