import { Controller } from "@hotwired/stimulus"
import {
  Scene,
  Color,
  PlaneGeometry,
  ShaderMaterial,
  Vector3,
  Mesh,
  OrthographicCamera,
  WebGLRenderer,
  DisplayP3ColorSpace,
  Clock,
} from "three"

import fragmentShader from "../shaders/gradientFragmentShader"
import vertexShader from "../shaders/gradientVertexShader"

export default class extends Controller {
  static targets = ["canvas", "palette"]

  static values = {
    colors: Array,
  }

  three = {}

  connect() {
    const scene = new Scene()
    scene.background = new Color(0x000000)

    const gradientGeometry = new PlaneGeometry(2, 2, 40, 40)
    this.three.gradientMaterial = new ShaderMaterial({
      uniforms: {
        iTime: { value: 0 },
        iResolution: { value: new Vector3() },
        color1: {
          value: new Color(this.colorsValue[0]),
        },
        color2: {
          value: new Color(this.colorsValue[1]),
        },
        color3: {
          value: new Color(this.colorsValue[2]),
        },
        color4: {
          value: new Color(this.colorsValue[3]),
        },
      },
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
    })
    const gradientPlane = new Mesh(
      gradientGeometry,
      this.three.gradientMaterial,
    )
    scene.add(gradientPlane)

    this.three.camera = new OrthographicCamera(
      -1, // left
      1, // right
      1, // top
      -1, // bottom
      -1, // near,
      1, // far
    )

    this.three.renderer = new WebGLRenderer({
      canvas: this.canvasTarget,
      antialias: true,
    })
    this.three.renderer.outputColorSpace = DisplayP3ColorSpace
    this.three.renderer.setSize(this.sizes.width, this.sizes.height)
    this.three.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    this.resize()

    const clock = new Clock()
    let previousTime = 0

    const tick = () => {
      const elapsedTime = clock.getElapsedTime()
      const deltaTime = elapsedTime - previousTime
      previousTime = elapsedTime

      this.three.gradientMaterial.uniforms.iResolution.value.set(
        this.sizes.width,
        this.sizes.height,
        1,
      )
      this.three.gradientMaterial.uniforms.iTime.value = elapsedTime

      this.three.renderer.render(scene, this.three.camera)

      window.requestAnimationFrame(tick)
    }

    tick()
  }

  paletteTargetConnected(target) {
    const colors = JSON.parse(target.dataset.colors)
    if (this.three.gradientMaterial == undefined) {
      return
    }

    this.three.gradientMaterial.uniforms.color1.value.set(new Color(colors[0]))
    this.three.gradientMaterial.uniforms.color2.value.set(new Color(colors[1]))
    this.three.gradientMaterial.uniforms.color3.value.set(new Color(colors[2]))
    this.three.gradientMaterial.uniforms.color4.value.set(new Color(colors[3]))
  }

  resize() {
    this.three.camera.aspect = this.sizes.width / this.sizes.height
    this.three.camera.updateProjectionMatrix()

    this.three.renderer.setSize(this.sizes.width, this.sizes.height)
    this.three.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  get sizes() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }
}

