import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["days", "hours", "minutes", "seconds"]

	static values = {
		deadline: String
	}

	connect() {
		this.#setCountdown(this.remainingTime)

		const interval = setInterval(() => {
			const remainingTime = this.remainingTime

			this.#setCountdown(remainingTime)

			if (remainingTime.total < 0) {
				clearInterval(interval)
			}
		}, 1000)
	}

	#setCountdown(remainingTime) {
		if (remainingTime.total < 0) {
			return
		}

		this.daysTarget.innerText = remainingTime.days.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false
		})
		this.hoursTarget.innerText = remainingTime.hours.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false
		})
		this.minutesTarget.innerText = remainingTime.minutes.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false
		})
		this.secondsTarget.innerText = remainingTime.seconds.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false
		})
	}

	get remainingTime() {
		const total = Date.parse(this.deadlineValue) - Date.parse(new Date())
		const days = Math.floor(total / (1000 * 60 * 60 * 24))
		const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
		const minutes = Math.floor((total / 1000 / 60) % 60)
		const seconds = Math.floor((total / 1000) % 60)

		return {
			total,
			days,
			hours,
			minutes,
			seconds
		}
	}
}
